import "./Footer.scss"

import React from "react"


const Footer = ({ data }) => {
  return (
    <div className="container footer">
      <div className="row">
        <div className="col-10 offset-1">
          <div className="row">
            <div className="col-8 offset-2 text-center py-5">
              <p>
                Email me at <a href="mailto:jake@canarykit.com">jake@canarykit.com</a>&nbsp;
                or connect with me online on&nbsp;
                <a href="https://www.linkedin.com/in/jakedahn/" target="_blank">Linkedin</a>,&nbsp;
                <a href="https://twitter.com/jakedahn/" target="_blank">Twitter</a>, or&nbsp;
                <a href="https://github.com/jakedahn/" target="_blank">Github</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer