import React from "react"
import { Link } from "gatsby"
import Logo from "!svg-react-loader!../../../content/assets/svg/logo.inline.svg?name=Logo";

import "./Header.scss"

const Header = ({ data }) => {

  return (
    <div className={`navbar-container`}>
      <div className="container">

        <nav className="navbar navbar-expand-sm">
          <Link to='/' className="logo text-center">
            <Logo className={'logo'} />
          </Link>
        </nav>
      </div>
    </div>
  )
}

export default Header

