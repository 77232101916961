import "../scss/pages/HomePage.scss"

import React from "react"

import Header from "../components/Header/Header.jsx"
import Footer from "../components/Footer/Footer.jsx"
import SEO from "../components/SEO.jsx"

import jakeHeadshot from "../../content/assets/headshots/jake.jpg"

const HomePage = ({ data }) => {
  return (
    <React.Fragment>
      <SEO title={`Canarykit - Independent Technology Consultant -- IT, DevOps/SRE, Infosec`}
            description={`👋 My name is Jake Dahn, and I'm an independent technology consultant with a passion for helping small to medium-sized businesses with their software and IT needs.`}
            keywords={[
              'Colorado IT',
              'CORPENG',
              'corporate engineering consultant',
              'Fullstack Engineer',
              'Software Engineer',
              'information security consultant',
              'IT consultant',
              'IT',
              'Jake Dahn',
              'Longmont IT',
              'technology consultant',
            ]}
      />

      <Header />

      <div className="container">
        <div className="blurb row">
        {/* col-md-6 offset-md-3 */}
          <div className="col-12 ">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                <p>Do you spend your time performing manual business management tasks? Is your office network always going offline? How 'bout the last time your video conferencing software dropped a call?</p>
                <p>If you answered yes to any of the questions above, you've come to the right place.</p>
              </div>
            </div>
          </div>
          <div className="col-10 offset-1 bio">
            <div className="row">
              <div className="mb-5 col-12 col-sm-6 offset-sm-3 mb-md-0 col-md-3 offset-md-0 col-lg-2">
                <img className="headshot" src={jakeHeadshot} alt="Headshot of Jake Dahn" />
              </div>
              <div className="bio-blurb col-12 col-md-9 col-lg-10">
                <p>👋 My name is Jake Dahn, and I'm an independent technology consultant with a passion for helping small to medium-sized businesses. </p>
                <p>With over a decade of experience as a Fullstack Software Engineer, DevOps Engineer, and IT / Automation Engineer, I have the knowledge and expertise you need to streamline and modernize your business.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
            <div className="row">
              <div className="col-12">
              <p>Whether you need help with modernizing your IT stack, building custom web applications, or help with information security, I'm your guy! I'm excited to learn more about your business and to figure out how I can help.</p>
              <p>If for some reason I can't help, I will happily share free resources, and make sure you're headed in the right direction.</p>
              </div>
              <div className="col-12 mt-5 cta text-center">
                <a href="https://calendly.com/canarykit/30min" target="_blank" className="btn btn-primary btn-lg text-center">Schedule a Free 30-minute Call</a>
              </div>
            </div>
          </div>
        </div>

        <hr className="skills-split" />

        <div className="skills row">
          <div className="col-8 offset-2 text-center py-5">
            <h4>Skills</h4>
            <p>Below is a summary of the skills and expertise that I can provide. Keep in mind that this is just a summary, and I may be able to help with things not on the list. If you're unsure, just schedule a quick call.</p>
          </div>

          <div className="col-4">
            <h6 className="text-center">Custom Software Applications</h6>
            <ul>
              <li>Fullstack Software Engineer (Python Django, Flask + React)</li>
              <li>Experience in most web languages (PHP, Ruby, Python, Javascript, Golang, etc)</li>
              <li>Experience with PostgreSQL and MySQL</li>
              <li>DevOps (ansible, saltstack, chef, puppet, etc).</li>
              <li>AWS, GCP, and Azure</li>
            </ul>
          </div>

          <div className="col-4">
            <h6 className="text-center">IT (Information Technology)</h6>
            <ul>
              <li>Identity (ADFS, Okta, Gsuite, etc)</li>
              <li>Collaboration Applications (Gsuite google apps, Microsoft Office365, etc)</li>
              <li>Video Conferencing</li>
              <li>Text Communication (Slack, Mattermost, etc)</li>
              <li>Single Sign-On (SSO Integrations)</li>
              <li>SaaS Application Management</li>
            </ul>
          </div>

          <div className="col-4">
            <h6 className="text-center">Information Security</h6>
            <ul>
              <li><a href="https://www.offensive-security.com/pwk-oscp/" target="_blank">OSCP Certification</a> (Offensive Security Certified Professional)</li>
              <li>Network Security Audit</li>
              <li>Web Application Code Review + Security Audit</li>
              <li>Penetration Testing</li>
              <li>Phishing Testing (How vulnerable is your business to phishing?)</li>
              <li>Employee Security Training</li>
            </ul>
          </div>

        </div>
      </div>

      <hr />

      <Footer />
    </React.Fragment>
  )
}
export default HomePage